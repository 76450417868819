<template>
	<h3 hidden>Please waiting, logout is in process....</h3>
</template>

<script>
    export default {
        name: "Logout",
        mounted() {
            this.$store.dispatch('auth/logout')
                .then(() => {
                    this.$router.push('/login')
                })
        }
    }
</script>

